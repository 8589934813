/* ==========================================================================
Hero styles
========================================================================== */

.hero-body {
  padding-top: 6rem;
  padding-bottom: 6rem;

  .title, .subtitle {
    font-family: "Source Sans Pro", sans-serif;

  }

  .title {
    &.is-bold {
      font-weight: 700;
    }

    text-transform: uppercase;
    letter-spacing: 5px;
  }

  .subtitle {
    &.is-muted {
      color: $muted-grey;
    }
  }
}

.hero-foot {
  img.partner-logo {
    height: 70px;
  }
}

video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -99;
}

#home-hero-video {
  @media screen and (max-width: 768px) {
    background-image: url('../images/bg/heart-mobile-heroimage.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1;
    background: #12407d;
    opacity: .75;


  }

  left: 0%;
  top: 0%;
  height: 1000px;
  width: 100%;
  overflow: hidden;
  position: absolute;
  z-index: -1;
  margin-top: -200px;

  @media screen and (min-width: 768px) {
  }
}