#torontomap, #stlouismap, #chicagomap, #atlantamap {
  height: 250px;
  width: 100%;
}

.location-tabs {

  .tabs.is-boxed a {
    transition: all .3s ease;
  }
  .tabs {
    margin-bottom: -1px !important;
    text-transform: uppercase;
    letter-spacing: 2px;
    border: none;
    font-size: .7rem;
    font-weight: 300;


    ul {
      justify-content: center;
      background-color: #E6E7E9;

    }
    @media (min-width: 768px) {
      font-size: 1.5rem;
      position: absolute;
      right: 0;
      ul {
        justify-content: flex-end;
      }
    }
  }

  .location-tab {
    background-color: #F2F2F2;

  }
  .tab-content-inner {
    padding: 30px;
    @media screen and (min-width: 768px) {
      padding: 100px 150px 80px 150px;
    }
    >.title {
      font-size: $size-4;
      font-weight: 300;
      text-transform: uppercase;
    }
    >.subtitle {
      font-size: $size-1;
      font-weight: 600;
      text-transform: uppercase;
    }
    .column>.title {
      font-size: $size-7;
      color: $primary;
      font-weight: 600;
      text-transform: uppercase;
    }
    .column>.subtitle {
      font-size: 18px;
      font-weight: 600;
      >a {
        text-decoration: none;
        color: #403e41;
        transition: all .3s ease;
        &:hover {
          color: $primary;
        }
      }
      >a>svg{
        margin-left: 5px;
        color: $primary;
      }
    }
  }


  .tab-pane {
    display: none;

  }

  .is-active {
    display: initial;
    background: transparent !important;

    a {
      border: none;
      border-radius: 0;
      background: transparent !important;
    }
  }
}
