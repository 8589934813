h1, h2, h3, h4, h5, h6 {
  letter-spacing: 2px;
}
p, blockquote {
}

.is-size-1 {
font-size: 3.125‬rem;
}

.is-size-18 {
  font-size: 1.125rem;
  &-desktop {
    @media screen and (min-width: 768px) {
      font-size: 1.125rem;
    }
  }
}