@charset "UTF-8";
/* ==========================================================================
Main SCSS file / Fresh
========================================================================== */
h1, h2, h3, h4, h5, h6 {
  letter-spacing: 2px;
}

.is-size-1 {
  font-size: 3.125‬rem;
}

.is-size-18 {
  font-size: 1.125rem;
}

@media screen and (min-width: 768px) {
  .is-size-18-desktop {
    font-size: 1.125rem;
  }
}

/* ==========================================================================
Color variables
========================================================================== */
.is-color-heartblue {
  color: #366DB5;
}

/* ==========================================================================
Navbar
========================================================================== */
.navbar.is-fresh {
  position: relative;
  min-height: 3.8rem;
  transition: all .3s;
  z-index: 29;
  font-size: 0.875rem;
}

.navbar.is-fresh .navbar-menu {
  border-bottom: 1px solid #366DB5;
  height: min-content;
}

@media screen and (max-width: 768px) {
  .navbar.is-fresh .navbar-menu {
    display: block;
    position: absolute;
    width: 100%;
    opacity: 0;
    transition: all .3s ease;
  }
  .navbar.is-fresh .navbar-menu.is-active {
    opacity: 1;
  }
}

.navbar.is-fresh .container {
  min-height: 4rem;
}

.navbar.is-fresh.no-shadow {
  box-shadow: none !important;
}

.navbar.is-fresh .navbar-burger {
  width: 4rem;
  height: 4rem;
  color: #fff;
}

.navbar.is-fresh .navbar-brand img {
  max-height: 150px;
  height: 150px;
}

.navbar.is-fresh .navbar-brand:hover .navbar-item {
  background: transparent !important;
}

@media screen and (min-width: 768px) {
  .navbar.is-fresh .navbar-brand {
    min-height: 4rem;
    position: absolute;
  }
  .navbar.is-fresh .navbar-brand img {
    max-height: 200px;
    height: 200px;
    margin-top: -40px;
  }
}

.navbar.is-fresh .navbar-end {
  align-items: flex-start;
}

.navbar.is-fresh .navbar-item {
  color: #fff;
  text-transform: uppercase;
  padding: 10px 30px;
  transition: all .3s ease;
}

.navbar.is-fresh .navbar-item.is-contact {
  background: #366DB5;
  padding: 10px;
}

@media (min-width: 768px) {
  .navbar.is-fresh .navbar-item.is-contact {
    padding: 10px 100px 10px 30px;
  }
}

.navbar.is-fresh .navbar-item.is-contact:hover {
  background: #2b3648 !important;
}

.navbar.is-fresh .navbar-item.is-secondary:hover {
  color: #dbdbdb !important;
}

.navbar.is-fresh .navbar-item.has-dropdown {
  padding: 10px 0;
}

.navbar.is-fresh .navbar-item.has-dropdown .navbar-link {
  color: #999;
}

.navbar.is-fresh .navbar-item.has-dropdown .navbar-link:after {
  top: 55%;
  height: 0.5em;
  width: 0.5em;
  border-width: 2px;
  border-color: #999;
}

.navbar.is-fresh .navbar-item.has-dropdown .navbar-dropdown {
  top: 3.4rem;
  min-width: 220px;
  margin-top: 4px;
  border-top-color: #366DB5;
}

.navbar.is-fresh .navbar-item.has-dropdown .navbar-dropdown .navbar-item {
  padding: 10px 20px;
}

.navbar.is-fresh .navbar-item.has-dropdown:hover .navbar-link {
  color: #366DB5;
}

.navbar.is-fresh .navbar-item.has-dropdown:hover .navbar-link:after {
  border-color: #366DB5;
}

.navbar.is-fresh .navbar-item .signup {
  display: block;
  line-height: 0;
  font-size: .9rem !important;
}

@media screen and (max-width: 768px) {
  .navbar.is-fresh .navbar-item {
    color: #366DB5;
  }
  .navbar.is-fresh .navbar-item.is-contact {
    color: white;
    padding: 0;
  }
}

.navbar.is-fresh.is-fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 4rem !important;
  background: #fff;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
}

.navbar.is-fresh.is-fixed a {
  color: #444F60;
}

.navbar.is-fresh.is-fixed a:hover {
  color: #366DB5;
}

#navbar-clone {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #fff;
  transform: translateY(-100%);
  z-index: 100;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
  color: #366DB5;
  transition: transform .3s ease;
}

#navbar-clone .navbar-item {
  text-transform: uppercase;
  padding: 10px 30px;
}

#navbar-clone .navbar-item.is-contact {
  padding: 10px 100px 10px 30px;
  background: #366DB5;
  color: white;
}

#navbar-clone .navbar-item.is-contact:hover {
  background: #2b3648;
}

#navbar-clone .navbar-item.is-secondary:hover {
  color: #366DB5 !important;
}

#navbar-clone.is-active {
  transform: translateY(0);
}

#navbar-clone .navbar-brand img {
  max-height: 100px !important;
  height: 100px;
}

.topbar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 5px 90px 5px 0px;
  background: white;
  text-transform: uppercase;
  font-size: .75rem;
}

.topbar .topbar-location {
  padding: 5px 20px;
  display: inline-block;
}

.topbar .topbar-location:last-child {
  padding-right: 0;
}

.topbar .topbar-location a {
  text-decoration: none;
  color: #366DB5;
}

.topbar .topbar-location a:hover {
  color: #344258;
}

@media screen and (max-width: 768px) {
  .topbar {
    display: none;
  }
}

/* ==========================================================================
Dropdown styles
========================================================================== */
div.nav-item.is-drop a {
  padding-right: 7px;
}

div.nav-item.is-drop:hover .dropContain .dropOut {
  opacity: 1;
}

div.nav-item.is-drop:hover, div.nav-item.is-drop:hover a {
  border-bottom: 1px solid transparent !important;
  color: #366DB5;
}

div.nav-item.is-drop:hover .dropContain {
  top: 65px;
  animation: fadeInUp 0.27s ease-out;
}

span.drop-caret {
  position: relative;
  top: 5px;
}

div.nav-item.is-drop {
  position: relative;
}

div.nav-item.is-drop .dropContain {
  width: 220px;
  position: absolute;
  z-index: 3;
  left: 50%;
  margin-left: -110px;
  /* half of width */
  top: -400px;
}

div.nav-item.is-drop .dropContain .dropOut {
  width: 220px;
  background: #fff;
  float: left;
  position: relative;
  margin-top: 15px;
  opacity: 0;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.15);
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.15);
  -webkit-transition: all .5s ease-out;
  -moz-transition: all .5s ease-out;
  -ms-transition: all .5s ease-out;
  -o-transition: all .5s ease-out;
  transition: all .5s ease-out;
}

div.nav-item.is-drop .dropContain .dropOut .triangle {
  width: 0;
  height: 0;
  position: absolute;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #fff;
  top: -8px;
  left: 50%;
  margin-left: -8px;
}

div.nav-item.is-drop .dropContain .dropOut ul li {
  text-align: left;
  float: left;
  width: 200px;
  padding: 12px 0 10px 15px;
  margin: 0px 10px;
  color: #777;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-transition: background .1s ease-out;
  -moz-transition: background .1s ease-out;
  -ms-transition: background .1s ease-out;
  -o-transition: background .1s ease-out;
  transition: background .1s ease-out;
}

div.nav-item.is-drop .dropContain .dropOut ul li:hover {
  background: #EFF4F7;
  cursor: pointer;
}

div.nav-item.is-drop .dropContain .dropOut ul {
  float: left;
  padding: 10px 0;
}

.body-a .column {
  min-height: 400px;
  background-position: 50% center;
  background-size: cover;
}

.whatsets-row {
  min-height: 550px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-color: #f1f2f2;
}

.heart-list {
  padding: 60px 40px;
}

.heart-list li {
  font-size: 0.875rem;
  position: relative;
  margin-bottom: 15px;
  padding: 5px 10% 5px 5px;
  font-weight: 300;
}

@media screen and (min-width: 768px) {
  .heart-list li {
    padding: 5px 30% 5px 5px;
  }
}

.heart-list li:before {
  content: "\f061";
  font-family: "Font Awesome 5 Pro";
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
  /*--adjust as necessary--*/
  color: #D1D3D4;
  font-size: 1rem;
  padding-right: 0.5em;
  position: absolute;
  left: -20px;
}

.heart-list .title {
  text-weight: 600;
  font-size: 1rem;
}

.heart-list:before {
  content: '';
  position: relative;
  width: 30%;
  background-color: black;
  height: 3px;
  top: 0;
  left: 0;
}

.partner-logo .column {
  display: flex;
  justify-content: center;
}

.partner-logo img {
  max-height: 140px;
}

.bodysection {
  padding-top: 40px;
  padding-bottom: 40px;
}

.bodysection-large {
  padding-top: 100px;
  padding-bottom: 100px;
}

.contact-inner-container {
  padding: 20px;
}

@media screen and (min-width: 768px) {
  .contact-inner-container {
    padding: 50px;
  }
}

.section {
  overflow: hidden;
}

a {
  transition: all .3s ease;
}

/* ==========================================================================
Section Styles
========================================================================== */
.section.section-light-grey {
  background-color: #EFF4F7;
}

.section.section-feature-grey {
  background-color: #fbfbfb;
}

.section.section-secondary {
  background-color: #366DB5;
}

.section.section-half {
  height: 75vh !important;
}

.section .title, .section .subtitle {
  font-family: "Source Sans Pro", sans-serif;
}

.section .subtitle.is-muted {
  color: #999;
}

.title-wrapper {
  max-width: 500px;
  margin: 0 auto;
}

.title-wrapper .title, .title-wrapper .subtitle {
  font-family: 'Open Sans', sans-serif;
}

.title-wrapper .subtitle.is-muted {
  color: #999;
}

.divider {
  height: 3px;
  border-radius: 50px;
  background: #366DB5;
  width: 60px;
}

.divider.is-centered {
  margin: 0 auto;
}

.content-wrapper {
  padding: 60px 0;
}

img.pushed-image {
  margin-top: -29vh;
}

.media.icon-box {
  border-top: none !important;
}

.media.icon-box .media-content .content p span {
  display: block;
}

.media.icon-box .media-content .content p .icon-box-title {
  color: #444F60;
  font-size: 1.2rem;
  font-weight: 600;
}

.media.icon-box .media-content .content p .icon-box-text {
  color: #A9ABAC;
  font-size: 1rem;
  font-weight: 400;
}

/* ==========================================================================
Hero styles
========================================================================== */
.hero-body {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.hero-body .title, .hero-body .subtitle {
  font-family: "Source Sans Pro", sans-serif;
}

.hero-body .title {
  text-transform: uppercase;
  letter-spacing: 5px;
}

.hero-body .title.is-bold {
  font-weight: 700;
}

.hero-body .subtitle.is-muted {
  color: #999;
}

.hero-foot img.partner-logo {
  height: 70px;
}

video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -99;
}

#home-hero-video {
  left: 0%;
  top: 0%;
  height: 1000px;
  width: 100%;
  overflow: hidden;
  position: absolute;
  z-index: -1;
  margin-top: -200px;
}

@media screen and (max-width: 768px) {
  #home-hero-video {
    background-image: url("../images/bg/heart-mobile-heroimage.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
}

#home-hero-video::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1;
  background: #12407d;
  opacity: .75;
}

/* ==========================================================================
Fresh Footer
========================================================================== */
footer.footer-dark {
  background: #444F60;
  color: #fff;
}

footer.footer-dark .columns {
  margin-top: 35px;
}

footer.footer-dark .footer-logo img {
  height: 40px;
}

footer.footer-dark .footer-column .footer-header h3 {
  font-weight: 500;
  font-size: 1.2rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 20px;
}

footer.footer-dark .footer-column ul.link-list {
  line-height: 40px;
  font-size: 1.1rem;
}

footer.footer-dark .footer-column ul.link-list a {
  color: #98a9c3;
  font-weight: 400;
  transition: all 0.5s;
}

footer.footer-dark .footer-column ul.link-list :hover {
  color: #fcfcfc;
}

footer.footer-dark .footer-column .level-item .icon {
  color: #366DB5;
  transition: all 0.5s;
}

footer.footer-dark .footer-column .level-item .icon :hover {
  color: #fcfcfc;
}

.footer-link {
  color: white;
  text-decoration: none;
}

.footer-link:hover {
  color: #ededed;
}

.footer-link:hover svg {
  color: #ededed;
}

/* ==========================================================================
Classes to change the feel of bulma buttons
========================================================================== */
.button {
  border-radius: 2px;
}

.button {
  cursor: pointer;
  transition: all 0.5s;
}

.button.cta {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1rem;
  font-weight: 600;
  padding: 26px 40px 26px 40px;
  background-color: #F1F2F2;
  border: none;
  text-transform: uppercase;
  color: #414042;
  transition: all .3s ease;
}

.button.cta:hover {
  background: #366DB5;
  color: white;
}

.button.is-clear {
  line-height: 0 !important;
}

.button.rounded {
  border-radius: 500px;
}

.button.raised:hover {
  box-shadow: 0 14px 26px -12px rgba(0, 0, 0, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2) !important;
  opacity: 0.8;
}

.button.btn-outlined {
  background: transparent;
}

.button.signup-button {
  font-size: 0.875rem;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  padding: 24px 26px;
  width: 130px;
}

.button.primary-btn {
  outline: none;
  border-color: #fff;
  background-color: transparent;
  color: #fff;
  transition: all 0.3s ease;
}

.button.primary-btn.is-largesmalltext {
  font-size: 1rem;
  padding: 40px 160px;
  text-transform: uppercase;
}

@media screen and (max-width: 768px) {
  .button.primary-btn.is-largesmalltext {
    padding: 30px 50px;
  }
}

.button.primary-btn:hover {
  color: black;
  background-color: white;
  box-shadow: #EFF4F7 5px;
  transition: all .3s ease;
}

.button.primary-btn.raised:hover {
  box-shadow: 0 14px 26px -12px rgba(79, 193, 234, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(79, 193, 234, 0.2) !important;
  opacity: 0.8;
  box-shadow: 10px 10px 5px #aaaaaa;
}

.button.primary-btn.btn-outlined {
  border-color: #366DB5;
  color: #366DB5;
  background-color: transparent;
}

.button.primary-btn.btn-outlined:hover {
  color: #fff;
  background-color: #366DB5;
}

.button.secondary-btn {
  outline: none;
  border-color: #366DB5;
  background-color: #366DB5;
  border-width: 3px;
  color: #fff;
  transition: all 0.5s;
  font-weight: bold;
}

.button.secondary-btn.is-largesmalltext {
  font-size: 1rem;
  padding: 40px 160px;
  text-transform: uppercase;
}

@media screen and (max-width: 768px) {
  .button.secondary-btn.is-largesmalltext {
    padding: 30px 50px;
  }
}

.button.secondary-btn:hover {
  color: #fff;
}

.button.secondary-btn.raised:hover {
  box-shadow: 0 14px 26px -12px rgba(243, 146, 0, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(243, 146, 0, 0.2) !important;
  opacity: 0.8;
}

.button.secondary-btn.btn-outlined {
  border-color: #366DB5;
  color: #366DB5;
  border-width: 3px;
  background-color: transparent;
}

.button.secondary-btn.btn-outlined:hover {
  color: #fff;
  background-color: #366DB5;
}

.button.button.accent-btn {
  outline: none;
  border-color: #00efb7;
  background-color: #00efb7;
  color: #fff;
  transition: all 0.5s;
}

.button.button.accent-btn.is-largesmalltext {
  font-size: 1rem;
  padding: 40px 160px;
  text-transform: uppercase;
}

@media screen and (max-width: 768px) {
  .button.button.accent-btn.is-largesmalltext {
    padding: 30px 50px;
  }
}

.button.button.accent-btn:hover {
  color: #fff;
}

.button.button.accent-btn.raised:hover {
  box-shadow: 0 14px 26px -12px rgba(104, 187, 136, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(104, 187, 136, 0.2) !important;
  opacity: 0.8;
}

.button.button.accent-btn.btn-outlined {
  border-color: #00efb7;
  color: #00efb7;
  background-color: transparent;
}

.button.button.accent-btn.btn-outlined:hover {
  color: #fff;
  background-color: #00efb7;
}

.button.heart-btn {
  outline: none;
  border-color: #fff;
  border-width: 3px;
  background-color: transparent;
  background-image: url("../images/general/leaf-bg-white.svg");
  background-size: 100%;
  background-position-y: -75px;
  background-position-x: -30px;
  background-repeat: no-repeat;
  color: #fff;
  transition: all 0.3s ease;
  font-size: 0.875rem;
  padding: 40px 160px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-weight: 600;
}

.button.heart-btn button {
  padding: 40px 160px 50px 160px !important;
}

@media screen and (max-width: 768px) {
  .button.heart-btn button {
    padding: 30px 50px 40px 50px !important;
  }
}

@media screen and (max-width: 768px) {
  .button.heart-btn {
    padding: 30px 50px;
    width: 100%;
  }
}

.button.heart-btn:hover {
  color: #fff;
  background-color: #366DB5;
  transition: all .3s ease;
}

.button.heart-btn.raised:hover {
  box-shadow: 0 14px 26px -12px rgba(104, 187, 136, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(104, 187, 136, 0.2) !important;
  opacity: 0.8;
}

.button.heart-btn.btn-outlined {
  border-color: #366DB5;
  color: #366DB5;
  background-color: transparent;
  background-image: url("../images/general/leaf-bg-blue.svg");
}

.button.heart-btn.btn-outlined:hover {
  color: #fff;
  background-color: #366DB5;
  background-image: url("../images/general/leaf-bg-white.svg");
}

button.heart-btn {
  outline: none;
  border-color: #fff;
  border-width: 3px;
  background-color: transparent;
  background-image: url("../images/general/leaf-bg-white.svg");
  background-size: 100%;
  background-position-y: -75px;
  background-position-x: -30px;
  background-repeat: no-repeat;
  color: #fff;
  transition: all 0.3s ease;
  font-size: 0.875rem;
  padding: 40px 160px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-weight: 600;
}

button.heart-btn button {
  padding: 40px 160px 50px 160px !important;
}

@media screen and (max-width: 768px) {
  button.heart-btn button {
    padding: 30px 50px 40px 50px !important;
  }
}

@media screen and (max-width: 768px) {
  button.heart-btn {
    padding: 30px 50px;
    width: 100%;
  }
}

button.heart-btn:hover {
  color: #fff;
  background-color: #366DB5;
  transition: all .3s ease;
}

button.heart-btn.raised:hover {
  box-shadow: 0 14px 26px -12px rgba(104, 187, 136, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(104, 187, 136, 0.2) !important;
  opacity: 0.8;
}

button.heart-btn.btn-outlined {
  border-color: #366DB5;
  border: solid;
  color: #366DB5;
  background-color: transparent;
  background-image: url("../images/general/leaf-bg-blue.svg");
}

button.heart-btn.btn-outlined:hover {
  color: #fff;
  background-color: #366DB5;
  background-image: url("../images/general/leaf-bg-white.svg");
}

/*! _cards.scss v1.0.0 | Commercial License | built on top of bulma.io/Bulmax */
/* ==========================================================================
Cards and Card content styles
========================================================================== */
.feature-card {
  width: 300px;
  height: 320px;
  background-color: #fff;
  border-radius: 3px;
  margin: 0 auto;
}

.feature-card .card-title h4 {
  font-family: 'Open Sans', sans-serif;
  padding-top: 25px;
  font-size: 1.2rem;
  font-weight: 600;
  color: #444F60;
}

.feature-card .card-icon img {
  height: 120px;
  margin-top: 20px;
}

.feature-card .card-text {
  padding: 0 40px;
}

.feature-card .card-text p {
  color: #999;
}

.feature-card .card-action {
  margin-top: 10px;
}

.feature-card.is-bordered {
  border: 1px solid #ededed;
}

.flex-card {
  position: relative;
  background-color: #fff;
  border: 0;
  border-radius: 0.1875rem;
  display: inline-block;
  position: relative;
  overflow: hidden;
  width: 100%;
  margin-bottom: 20px;
}

.flex-card.raised {
  box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);
}

.flex-card .tabs {
  padding: 15px 0.7rem;
}

.flex-card .navtab-content {
  min-height: 190px;
}

.flex-card .navtab-content p {
  padding: 0 0.8rem 20px;
}

.flex-card .navigation-tabs.outlined-pills .tabs.tabs-header.primary {
  background-color: #366DB5;
}

.flex-card .navigation-tabs.outlined-pills .tabs.tabs-header.secondary {
  background-color: #366DB5;
}

.flex-card .navigation-tabs.outlined-pills .tabs.tabs-header.accent {
  background-color: #00efb7;
}

.flex-card .navigation-tabs.outlined-pills .tabs.tabs-header ul li a {
  color: #f2f2f2;
}

.flex-card .navigation-tabs.outlined-pills .tabs.tabs-header ul li.is-active a {
  color: #fff;
  border: 1px solid #fff;
  border-bottom-color: #fff !important;
}

.modal .auth-card {
  max-width: 460px;
  margin: 0 auto;
  border-radius: 6px;
}

.modal .auth-card .tabs {
  margin-bottom: 0;
}

.modal .auth-card .tabs li a {
  color: #cecece;
}

.modal .auth-card .tabs li.is-active a {
  color: #366DB5;
  border-bottom-color: #366DB5;
}

.modal .auth-card .location-tab {
  padding: 20px;
}

.modal .auth-card .location-tab .field {
  max-width: 390px;
  margin: 10px auto;
}

.modal .auth-card .location-tab .field label {
  display: block;
  font-weight: 500;
  font-size: .9rem;
}

.modal .auth-card .location-tab .field .input {
  font-size: .95rem;
  height: 44px;
}

.modal .auth-card .location-tab .button.is-fullwidth {
  padding: 20px 0;
  max-width: 390px;
  margin: 20px auto;
}

.icon-card {
  height: 200px;
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 20px;
}

.icon-card p {
  line-height: 1.4;
}

.icon-card .title {
  letter-spacing: 2px;
  font-size: 1.5rem;
}

.icon-card icon {
  font-size: 80px;
}

@media (min-width: 768px) {
  .icon-card {
    height: 250px;
    min-height: 250px;
    margin: 0;
  }
}

.group {
  position: relative;
  margin-bottom: 45px;
}

input, textarea {
  font-size: 18px;
  padding: 10px 10px 10px 5px;
  display: block;
  width: 100%;
  border: none;
  border-bottom: 1px solid #757575;
}

input:focus, textarea:focus {
  outline: none;
}

/* LABEL ======================================= */
label {
  color: #999;
  font-size: 18px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 10px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

/* active state */
input:focus ~ label, input:valid ~ label, textarea:focus ~ label, textarea:valid ~ label {
  top: -20px;
  font-size: 14px;
  color: #5264AE;
}

/* BOTTOM BARS ================================= */
.bar {
  position: relative;
  display: block;
  width: 100%;
}

.bar:before, .bar:after {
  content: '';
  height: 2px;
  width: 0;
  bottom: 1px;
  position: absolute;
  background: #5264AE;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

.bar:before {
  left: 50%;
}

.bar:after {
  right: 50%;
}

/* active state */
input:focus ~ .bar:before, input:focus ~ .bar:after {
  width: 50%;
}

textarea:focus ~ .bar:before, textarea:focus ~ .bar:after {
  width: 50%;
}

/* HIGHLIGHTER ================================== */
.highlight {
  position: absolute;
  height: 60%;
  width: 100%;
  top: 25%;
  left: 0;
  pointer-events: none;
  opacity: 0.5;
}

/* active state */
input:focus ~ .highlight, textarea:focus ~ .highlight {
  -webkit-animation: inputHighlighter 0.3s ease;
  -moz-animation: inputHighlighter 0.3s ease;
  animation: inputHighlighter 0.3s ease;
}

/* ANIMATIONS ================ */
@-webkit-keyframes inputHighlighter {
  from {
    background: #5264AE;
  }
  to {
    width: 0;
    background: transparent;
  }
}

@-moz-keyframes inputHighlighter {
  from {
    background: #5264AE;
  }
  to {
    width: 0;
    background: transparent;
  }
}

@keyframes inputHighlighter {
  from {
    background: #5264AE;
  }
  to {
    width: 0;
    background: transparent;
  }
}

/* ==========================================================================
General Keyframes animations
========================================================================== */
.animated {
  animation-duration: 0.5s;
  animation-fill-mode: both;
  -webkit-animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
}

.delay-1 {
  animation-delay: .25s;
}

.delay-2 {
  animation-delay: .5s;
}

.delay-3 {
  animation-delay: .75s;
}

.delay-4 {
  animation-delay: 1s;
}

@keyframes fadeInLeft {
  from {
    -webkit-transform: translate3d(20px, 0, 0);
    transform: translate3d(20px, 0, 0);
    opacity: 0;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@-webkit-keyframes fadeInLeft {
  from {
    -webkit-transform: translate3d(20px, 0, 0);
    transform: translate3d(20px, 0, 0);
    opacity: 0;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.preFadeInLeft {
  opacity: 0;
}

.fadeInLeft {
  opacity: 0;
  animation-name: fadeInLeft;
  -webkit-animation-name: fadeInLeft;
}

@keyframes fadeInUp {
  from {
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@-webkit-keyframes fadeInUp {
  from {
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.preFadeInUp {
  opacity: 0;
}

.fadeInUp {
  opacity: 0;
  animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp;
}

.gelatine {
  animation: gelatine 0.6s;
  animation-duration: 0.6s;
  -webkit-animation-duration: 0.5s;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
}

@keyframes gelatine {
  from, to {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(0.9, 1.1);
  }
  50% {
    transform: scale(1.1, 0.9);
  }
  75% {
    transform: scale(0.95, 1.05);
  }
}

/* ==========================================================================
Sidebar Styles 
========================================================================== */
.menu-icon-wrapper {
  position: relative;
  left: 0;
  top: 0;
  width: 34px;
  height: 34px;
  pointer-events: none;
  transition: 0.1s;
}

.menu-icon-wrapper svg {
  position: absolute;
  top: -18px;
  left: -18px;
  transform: scale(0.07);
  transform-origin: 0 0;
}

.menu-icon-wrapper svg path {
  stroke: #366DB5;
  stroke-width: 40px;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: transparent;
  transition: stroke-dasharray 0.5s;
}

.menu-icon-wrapper svg path.path1 {
  stroke-dashoffset: 5803.15px;
  stroke-dasharray: 2901.57px, 2981.57px, 240px;
}

.menu-icon-wrapper svg path.path2 {
  stroke-dashoffset: 800px;
  stroke-dasharray: 400px, 480px, 240px;
}

.menu-icon-wrapper svg path.path3 {
  stroke-dashoffset: 6993.11px;
  stroke-dasharray: 3496.56px, 3576.56px, 240px;
}

.menu-icon-wrapper.open svg path.path1 {
  stroke-dasharray: 2901.57px, 5258.15px, 240px;
}

.menu-icon-wrapper.open svg path.path2 {
  stroke-dasharray: 400px, 600px, 0px;
}

.menu-icon-wrapper.open svg path.path3 {
  stroke-dasharray: 3496.56px, 6448.11px, 240px;
}

.menu-icon-wrapper .menu-icon-trigger {
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;
  pointer-events: auto;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
}

.sidebar {
  background: #344258;
  width: 280px;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  transform: translateX(-281px);
  transition: all .3s;
  z-index: 10000;
}

.sidebar.is-active {
  transform: translateX(0);
}

.sidebar .sidebar-header {
  height: 4.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #3d4e68;
  padding: 0 20px;
}

.sidebar .sidebar-header img {
  height: 32px;
}

.sidebar .sidebar-header a {
  width: 24px;
  height: 24px;
}

.sidebar .sidebar-header svg {
  stroke: #fff;
  transform: rotate(0);
  transition: all .3s;
  cursor: pointer;
}

.sidebar .sidebar-header svg:hover {
  stroke: #366DB5;
  transform: rotate(180deg);
}

.sidebar .inner {
  position: relative;
}

.sidebar .inner .sidebar-menu {
  margin: 0;
  padding: 0;
  max-width: 400px;
  list-style: none;
  list-style-type: none;
  font-family: 'Open Sans', sans-serif !important;
}

.sidebar .inner .sidebar-menu li a {
  padding: 20px 25px;
  display: block;
  text-decoration: none;
  color: #fff;
}

.sidebar .inner .sidebar-menu li a:hover {
  padding: 20px 25px;
  display: block;
  text-decoration: none;
  color: #fff;
}

.sidebar .inner .sidebar-menu li a span {
  margin-right: 20px;
  color: #fff;
}

.sidebar .inner .sidebar-menu li.have-children ul {
  padding: 0px;
}

.sidebar .inner .sidebar-menu li.have-children li a {
  background-color: #2b3648;
  padding-left: 62px;
  border-bottom: 1px solid #303d52;
  font-size: .8rem;
}

.sidebar .inner .sidebar-menu li.have-children li a:hover {
  color: #366DB5;
  padding-left: 62px;
}

.sidebar .inner .sidebar-menu li.have-children span::after {
  position: absolute;
  top: 27px;
  right: 30px;
  content: "\f054";
  color: #fff;
  transition: all .5s;
  font-weight: 200 !important;
  font-size: .8rem;
}

.sidebar .inner li.have-children, .sidebar .inner li {
  position: relative;
}

.sidebar .inner li.have-children.active > a, .sidebar .inner li.have-children.active > a span, .sidebar .inner li.have-children.active > a span:after {
  color: #366DB5;
}

.sidebar .inner li.active.have-children span::after {
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.sidebar .inner .sidebar-menu .have-children > ul {
  display: none;
}

/* ==========================================================================
Testimonials Styles
========================================================================== */
.testimonial {
  position: relative;
  overflow: hidden;
  margin: 10px auto;
  min-width: 220px;
  max-width: 310px;
  width: 100%;
  color: #333;
  text-align: left;
  box-shadow: none !important;
}

.testimonial * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.testimonial img {
  max-width: 100%;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  margin-right: 5px;
  display: block;
  z-index: 1;
  position: absolute;
  right: 60%;
}

.testimonial blockquote {
  margin: 0;
  display: block;
  border-radius: 8px;
  position: relative;
  background-color: #fcfcfc;
  padding: 30px 50px 65px 50px;
  font-size: 1.2rem;
  font-weight: 500;
  margin: 0 0 -40px;
  line-height: 1.6em;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
}

.testimonial blockquote:before, .testimonial blockquote:after {
  font-family: 'FontAwesome';
  content: "\f10d";
  position: absolute;
  font-size: 20px;
  opacity: 0.3;
  font-style: normal;
}

.testimonial blockquote:before {
  top: 35px;
  left: 20px;
}

.testimonial blockquote:after {
  content: "\f10e";
  right: 20px;
  bottom: 35px;
}

.testimonial .author {
  margin: 0;
  height: 80px;
  display: block;
  text-align: left;
  color: #fff;
  padding: 0 35px;
  position: relative;
  z-index: 0;
}

.testimonial .author h5, .testimonial .author span {
  left: 50%;
  position: absolute;
  opacity: 0.8;
  padding: 3px 5px;
}

.testimonial .author h5 {
  text-transform: capitalize;
  bottom: 60%;
  margin: 0;
  font-weight: 600;
  font-size: 1.2rem;
  color: #444F60;
}

.testimonial .author span {
  font-size: 0.8em;
  color: #fff;
  top: 50%;
}

.testimonials-container .column {
  background-size: cover;
  background-repeat: no-repeat;
}

.carousel {
  overflow: hidden;
  min-height: 500px;
}

.carousel .slider {
  min-height: 500px;
}

.carousel .slider-container {
  min-height: 500px;
}

#testimonial-carousel .carousel-item {
  background-color: #F1F2F2;
  background-image: url(../images/general/maple-leaf-bg.svg);
  background-size: 100%;
  background-position-y: -200px;
  background-position-x: -200px;
  background-repeat: no-repeat;
  min-height: 600px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  background-size: 110%;
  min-height: 500px;
}

#testimonial-carousel .carousel-item p {
  color: #414042;
}

@media screen and (min-width: 768px) {
  #testimonial-carousel .carousel-item {
    padding-top: 10%;
  }
}

/* ==========================================================================
Responsive Styles
========================================================================== */
@media (max-width: 767px) {
  .landing-caption {
    text-align: center;
  }
  .navbar-menu .is-static {
    position: absolute;
    width: 100%;
  }
  .navbar-menu .is-fixed {
    position: fixed;
    width: 100%;
  }
  .navbar-menu .navbar-item {
    text-align: center !important;
  }
  .navbar-menu .navbar-item .signup-button {
    width: 100% !important;
  }
  .navbar-menu .navbar-link {
    padding: 10px 20px !important;
  }
  .title.section-title {
    font-size: 2rem !important;
  }
  .level-left.level-social {
    display: flex;
    justify-content: flex-start;
  }
  .pushed-image {
    margin-top: 0 !important;
  }
  .testimonial {
    margin: 0 auto;
  }
  .testimonial blockquote {
    font-size: 1rem;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .landing-caption {
    text-align: center;
  }
  .landing-caption .subtitle {
    max-width: 440px;
    margin: 0 auto;
    margin-bottom: 20px;
  }
  form {
    padding: 0 40px;
  }
  .hero-body {
    padding-bottom: 0;
  }
  .hero-body img {
    display: block;
    margin: 0 auto;
    max-height: 450px !important;
    max-width: 450px !important;
  }
  .navbar-menu .is-static {
    position: absolute;
    width: 100%;
  }
  .navbar-menu .is-fixed {
    position: fixed;
    width: 100%;
  }
  .navbar-menu .navbar-item {
    text-align: center !important;
  }
  .navbar-menu .navbar-item .signup-button {
    width: 100% !important;
  }
  .navbar-menu .navbar-link {
    padding: 10px 20px !important;
  }
  .pushed-image {
    margin-top: 0 !important;
  }
  .testimonial {
    margin: 0 auto;
  }
  .is-centered-tablet-portrait {
    text-align: center !important;
  }
  .is-centered-tablet-portrait .divider {
    margin: 0 auto !important;
  }
  .footer-logo, .footer-column {
    text-align: center;
  }
  .level.is-mobile {
    justify-content: center !important;
  }
  .level.is-mobile .level-item {
    margin: 0 .75rem !important;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .navbar-menu .navbar-end a {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .navbar-menu .navbar-end .navbar-link {
    padding-right: 0 !important;
  }
  .navbar-menu .navbar-end .button {
    min-width: 180px;
  }
  .navbar-item.is-hidden-mobile {
    display: none !important;
  }
  .navbar-item.is-hidden-desktop.is-hidden-tablet {
    display: flex !important;
  }
  .pushed-image {
    margin-top: 0 !important;
  }
}

section:focus {
  outline: none !important;
}

button:focus, button:active {
  outline: none;
}

#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 99;
}

#status {
  width: 200px;
  height: 200px;
  position: absolute;
  left: 50%;
  top: 50%;
  background-image: url(../images/loaders/rings.svg);
  background-size: 80px 80px;
  background-repeat: no-repeat;
  background-position: center;
  margin: -100px 0 0 -100px;
}

#backtotop {
  position: fixed;
  right: 0;
  opacity: 0;
  visibility: hidden;
  bottom: 25px;
  margin: 0 25px 0 0;
  z-index: 9999;
  transition: 0.35s;
  transform: scale(0.7);
  transition: all 0.5s;
}

#backtotop.visible {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}

#backtotop.visible a:hover {
  outline: none;
  opacity: 0.9;
  background: #366DB5;
}

#backtotop a {
  outline: none;
  text-decoration: none;
  border: 0 none;
  display: block;
  width: 46px;
  height: 46px;
  background-color: #66676b;
  opacity: 1;
  transition: all 0.3s;
  border-radius: 50%;
  text-align: center;
  font-size: 26px;
}

body #backtotop a {
  outline: none;
  color: #fff;
}

#backtotop a:after {
  outline: none;
  content: "\f106";
  font-family: "Font Awesome 5 Pro";
  position: relative;
  display: block;
  top: 50%;
  -webkit-transform: translateY(-55%);
  transform: translateY(-55%);
}

.lh-1 {
  line-height: 1rem !important;
}

.lh-15 {
  line-height: 1.5rem !important;
}

.lh-20 {
  line-height: 2rem !important;
}

.pd-20 {
  padding: 20px;
}

.pd-30 {
  padding: 30px;
}

.pd-40 {
  padding: 40px;
}

@media (min-width: 768px) {
  .pd-40-desktop {
    padding: 40px;
  }
}

.pd-60 {
  padding: 60px;
}

@media (min-width: 768px) {
  .pd-60-desktop {
    padding: 60px;
  }
}

.pd-80by60 {
  padding: 80px 60px;
}

@media (min-width: 768px) {
  .pd-80by60-desktop {
    padding: 80px 60px;
  }
}

.pd-140by60 {
  padding: 140px 60px;
}

.pd-140by60-desktop {
  padding: 40px;
}

@media (min-width: 768px) {
  .pd-140by60-desktop {
    padding: 140px 60px;
  }
}

.is-disabled {
  pointer-events: none;
  opacity: 0.4;
  cursor: default !important;
}

.is-bg-fixed {
  height: 100%;
  overflow: hidden;
}

.is-400px-minheight {
  min-height: 400px;
}

.is-letterspace-1px {
  letter-spacing: 1px;
}

.is-letterspace-15px {
  letter-spacing: 1.5px;
}

.is-letterspace-2px {
  letter-spacing: 2px;
}

.stuck {
  position: fixed !important;
  top: 0 !important;
  z-index: 2 !important;
}

.light-text {
  color: #fff !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mb-60 {
  margin-bottom: 60px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mt-60 {
  margin-top: 60px !important;
}

.ml-30 {
  margin-left: 30px;
}

.huge-pb {
  padding-bottom: 100px;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.pb-40 {
  padding-bottom: 40px !important;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #cecece;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: #cecece;
}

:-ms-input-placeholder {
  /* IE 10+ */
  color: #cecece;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: #cecece;
}

.is-flex-reverse-desktop {
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 768px) {
  .is-flex-reverse-desktop {
    flex-direction: column-reverse;
  }
}

.is-flex-center-center {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}

/*!
 * Font Awesome Pro 5.11.1 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 300;
  font-display: auto;
  src: url("../fonts/webfonts/fa-light-300.eot");
  src: url("../fonts/webfonts/fa-light-300.eot?#iefix") format("embedded-opentype"), url("../fonts/webfonts/fa-light-300.woff2") format("woff2"), url("../fonts/webfonts/fa-light-300.woff") format("woff"), url("../fonts/webfonts/fa-light-300.ttf") format("truetype"), url("../fonts/webfonts/fa-light-300.svg#fontawesome") format("svg");
}

.fal {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 300;
}

.serviceMap {
  width: 100%;
  height: auto;
}

.serviceMap [id^='label-'] {
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s;
}

.serviceMap [id^='map-'] {
  transition: all 0.3s;
}

.serviceMap [id^='map-']:hover {
  fill: #366DB5 !important;
}

.serviceMap [id^='map-']:hover * {
  fill: #366DB5 !important;
}

@-webkit-keyframes spinAround {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes spinAround {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.slider {
  position: relative;
  width: 100%;
}

.slider-container {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  min-height: 100%;
}

.slider-container.is-vertical {
  flex-direction: column;
}

.slider-container .slider-item {
  flex: none;
}

.slider-container .slider-item .image.is-covered img {
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center center;
  object-position: center center;
  height: 100%;
  width: 100%;
}

.slider-container .slider-item .video-container {
  height: 0;
  padding-bottom: 0;
  padding-top: 56.25%;
  margin: 0;
  position: relative;
}

.slider-container .slider-item .video-container.is-1by1, .slider-container .slider-item .video-container.is-square {
  padding-top: 100%;
}

.slider-container .slider-item .video-container.is-4by3 {
  padding-top: 75%;
}

.slider-container .slider-item .video-container.is-21by9 {
  padding-top: 42.857143%;
}

.slider-container .slider-item .video-container embed, .slider-container .slider-item .video-container iframe, .slider-container .slider-item .video-container object {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}

.slider-navigation-next, .slider-navigation-previous {
  display: flex;
  justify-content: center;
  align-items: center;
  fill: #366DB5;
  position: absolute;
  width: 42px;
  height: 42px;
  background: #fff center center no-repeat;
  background-size: 20px 20px;
  top: 50%;
  margin-top: -20px;
  left: auto;
  right: 42px;
  top: 20px;
  cursor: pointer;
  transition: opacity .3s, -webkit-transform .3s;
  transition: transform .3s, opacity .3s;
  transition: transform .3s, opacity .3s, -webkit-transform .3s;
}

.slider-navigation-next:hover, .slider-navigation-previous:hover {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

.slider-navigation-next svg, .slider-navigation-previous svg {
  width: 25%;
}

.slider-navigation-next {
  left: auto;
  right: 0;
  background: #fff center center no-repeat;
  background-size: 20px 20px .slider-navigation-next;
}

.slider-navigation-next ::before {
  content: '/';
  color: #366DB5;
}

.slider-pagination {
  display: none;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: .5rem 1rem;
  text-align: center;
}

.slider-pagination .slider-page {
  background: #fff;
  width: 10px;
  height: 10px;
  border-radius: 25091983px;
  display: inline-block;
  margin: 0 3px;
  box-shadow: 0 2px 5px #3232321a;
  transition: -webkit-transform .3s;
  transition: transform .3s;
  transition: transform .3s, -webkit-transform .3s;
  cursor: pointer;
}

.slider-pagination .slider-page.is-active, .slider-pagination .slider-page:hover {
  -webkit-transform: scale(1.4);
  transform: scale(1.4);
}

@media screen and (min-width: 800px) {
  .slider-pagination {
    display: flex;
  }
}

.hero.has-carousel {
  position: relative;
}

.hero.has-carousel + .hero-body, .hero.has-carousel + .hero-footer, .hero.has-carousel + .hero-head {
  z-index: 10;
  overflow: hidden;
}

.hero.has-carousel .hero-carousel {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: auto;
  border: none;
  margin: auto;
  padding: 0;
  z-index: 0;
}

.hero.has-carousel .hero-carousel .slider {
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  height: 100% !important;
  max-height: 100%;
  z-index: 0;
}

.hero.has-carousel .hero-carousel .slider .has-background {
  max-height: 100%;
}

.hero.has-carousel .hero-carousel .slider .has-background .is-background {
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center center;
  object-position: center center;
  height: 100%;
  width: 100%;
}

.hero.has-carousel .hero-body {
  margin: 0 3rem;
  z-index: 10;
}

.modal-content {
  width: unset;
  overflow: initial;
}

.modal-content .heart-list li {
  font-weight: 300;
}

.modal-content .container {
  max-height: 80vh;
  overflow-x: hidden;
  overflow-y: scroll;
}

@media screen and (min-width: 768px) {
  .modal-content .container {
    overflow: hidden;
  }
}

.modal-content .column .is-8 {
  padding: 20px;
}

.modal-content .column .is-4 {
  min-height: 300px;
}

.modal-content .columns {
  display: flex;
  flex-direction: column-reverse;
  overflow: scroll;
}

@media screen and (min-width: 768px) {
  .modal-content .column {
    min-height: 400px;
    padding: 80px;
  }
  .modal-content .columns {
    flex-direction: row;
    overflow: auto;
  }
}

.modal {
  z-index: 101;
}

@media screen and (min-width: 768px) {
  .modal {
    justify-content: center;
  }
}

.modal-close {
  position: relative;
  float: right;
  top: 0;
  right: 0;
}

.modal-close:before, .modal-close:after {
  background-color: black;
}

.modal-column-text {
  padding: 40px;
}

.modal-column-text .heart-list {
  columns: 1;
}

.modal-column-text .heart-list li {
  list-style: none;
}

.modal-column-text .heart-list::marker {
  display: none;
}

@media (min-width: 768px) {
  .modal-column-text {
    padding: 0px;
  }
  .modal-column-text .heart-list {
    padding: 0px 20px;
    columns: 2;
  }
  .modal-column-text .heart-list li {
    margin-bottom: 3px;
    list-style: none;
  }
  .modal-column-text .heart-list li::marker {
    display: none;
  }
}

.modal-column-image {
  background-position: center center !important;
}

@media (max-width: 768px) {
  .modal-column-image {
    min-height: 300px;
  }
}

.location-tabs .tabs ul {
  border: none;
}

#torontomap, #stlouismap, #chicagomap, #atlantamap {
  height: 250px;
  width: 100%;
}

.location-tabs .tabs.is-boxed a {
  transition: all .3s ease;
}

.location-tabs .tabs {
  margin-bottom: -1px !important;
  text-transform: uppercase;
  letter-spacing: 2px;
  border: none;
  font-size: .7rem;
  font-weight: 300;
}

.location-tabs .tabs ul {
  justify-content: center;
  background-color: #E6E7E9;
}

@media (min-width: 768px) {
  .location-tabs .tabs {
    font-size: 1.5rem;
    position: absolute;
    right: 0;
  }
  .location-tabs .tabs ul {
    justify-content: flex-end;
  }
}

.location-tabs .location-tab {
  background-color: #F2F2F2;
}

.location-tabs .tab-content-inner {
  padding: 30px;
}

@media screen and (min-width: 768px) {
  .location-tabs .tab-content-inner {
    padding: 100px 150px 80px 150px;
  }
}

.location-tabs .tab-content-inner > .title {
  font-size: 1.5rem;
  font-weight: 300;
  text-transform: uppercase;
}

.location-tabs .tab-content-inner > .subtitle {
  font-size: 3.125rem;
  font-weight: 600;
  text-transform: uppercase;
}

.location-tabs .tab-content-inner .column > .title {
  font-size: 0.875rem;
  color: #366DB5;
  font-weight: 600;
  text-transform: uppercase;
}

.location-tabs .tab-content-inner .column > .subtitle {
  font-size: 18px;
  font-weight: 600;
}

.location-tabs .tab-content-inner .column > .subtitle > a {
  text-decoration: none;
  color: #403e41;
  transition: all .3s ease;
}

.location-tabs .tab-content-inner .column > .subtitle > a:hover {
  color: #366DB5;
}

.location-tabs .tab-content-inner .column > .subtitle > a > svg {
  margin-left: 5px;
  color: #366DB5;
}

.location-tabs .tab-pane {
  display: none;
}

.location-tabs .is-active {
  display: initial;
  background: transparent !important;
}

.location-tabs .is-active a {
  border: none;
  border-radius: 0;
  background: transparent !important;
}

.services-container .column {
  position: relative;
  min-height: 250px;
  background-size: cover;
  background-repeat: no-repeat !important;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-position: center center !important;
  transition: all .3s ease-in-out;
}

.services-container .column .overlay {
  position: absolute;
  z-index: 1;
  opacity: 0;
  width: 100%;
  height: 100%;
  background: #366DB5;
  outline: 3px solid transparent;
  transition: all .3s ease;
}

.services-container .column:hover .overlay {
  opacity: .7;
  outline: 3px solid white;
  outline-offset: -30px;
}

.services-container .column:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: inherit;
  content: ' ';
  z-index: -2;
}

.services-container .column.service1 {
  background: url("../images/services/services-1.jpg");
}

.services-container .column.service2 {
  background: url("../images/services/services-2.jpg");
}

.services-container .column.service3 {
  background: url("../images/services/services-3.jpg");
}

.services-container .column.service4 {
  background: url("../images/services/services-4.jpg");
}

.services-container .column.service5 {
  background: url("../images/services/services-5.jpg");
}

.services-container .column.service6 {
  background: url("../images/services/services-6.jpg");
}

@media (min-width: 768px) {
  .services-container .column {
    min-height: 400px;
  }
}

.services-container .column .modal-trigger {
  margin-top: -20px;
  z-index: 2;
}

.services-container .services-title {
  color: white;
  font-size: 1.5rem;
  font-weight: 600;
  text-transform: uppercase;
  z-index: 2;
}

@media screen and (min-width: 768px) {
  .services-container .services-title {
    padding: 20px;
  }
}

.bg-container-fixed {
  min-height: 50%;
  max-height: 100vh;
  background-position: center center;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}

.bg-container-fixed-fullheight {
  min-height: 200px;
  height: 200px;
  background-position: center center !important;
  background-size: cover !important;
  background-repeat: no-repeat;
}

@media screen and (min-width: 768px) {
  .bg-container-fixed-fullheight {
    height: 100%;
    min-height: 100%;
  }
}
