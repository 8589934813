
.body-a {
  .column {
    min-height: 400px;
    background-position: 50% center;
    background-size: cover;
  }
}

.whatsets-row {
  min-height: 550px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-color: #f1f2f2;
}

.heart-list {
  padding: 60px 40px;


  li {
    font-size: $size-7;
    position: relative;
    margin-bottom: 15px;
    padding: 5px 10% 5px 5px;
    font-weight: 300;

    @media screen and (min-width: 768px) {
      padding: 5px 30% 5px 5px;

    }

    &:before {

      content: "\f061";
      font-family: "Font Awesome 5 Pro";
      font-style: normal;
      font-weight: normal;
      text-decoration: inherit;
      /*--adjust as necessary--*/
      color: #D1D3D4;
      font-size: 1rem;
      padding-right: 0.5em;
      position: absolute;
      left: -20px;

    }

  }

  .title {
    text-weight: 600;
    font-size: $size-6;

  }


  &:before {
    content: '';
    position: relative;
    width: 30%;
    background-color: black;
    height: 3px;
    top: 0;
    left: 0;
  }

}


.partner-logo {
  .column {
    display: flex;
    justify-content: center;
  }

  img {
    max-height: 140px;
  }
}

.bodysection {
  padding-top: 40px;
  padding-bottom: 40px;

  &-large {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

.contact-inner-container {
  padding: 20px;
  @media screen and (min-width: 768px) {
    padding: 50px;
  }
}

.section {
  overflow: hidden;
}

a {
  transition: all .3s ease;
}