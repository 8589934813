/* ==========================================================================
Testimonials Styles
========================================================================== */

.testimonial {
  position: relative;
  overflow: hidden;
  margin: 10px auto;
  min-width: 220px;
  max-width: 310px;
  width: 100%;
  color: #333;
  text-align: left;
  box-shadow: none !important;

  * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  img {
    max-width: 100%;
    height: 80px;
    width: 80px;
    border-radius: 50%;
    margin-right: 5px;
    display: block;
    z-index: 1;
    position: absolute;
    right: 60%;
  }

  blockquote {
    margin: 0;
    display: block;
    border-radius: 8px;
    position: relative;
    background-color: $smoke-white;
    padding: 30px 50px 65px 50px;
    font-size: 1.2rem;
    font-weight: 500;
    margin: 0 0 -40px;
    line-height: 1.6em;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  }

  blockquote:before, blockquote:after {
    font-family: 'FontAwesome';
    content: "\f10d";
    position: absolute;
    font-size: 20px;
    opacity: 0.3;
    font-style: normal;
  }

  blockquote:before {
    top: 35px;
    left: 20px;
  }

  blockquote:after {
    content: "\f10e";
    right: 20px;
    bottom: 35px;
  }

  .author {
    margin: 0;
    height: 80px;
    display: block;
    text-align: left;
    color: $white;
    padding: 0 35px;
    position: relative;
    z-index: 0;

    h5, span {
      left: 50%;
      position: absolute;
      opacity: 0.8;
      padding: 3px 5px;
    }

    h5 {
      text-transform: capitalize;
      bottom: 60%;
      margin: 0;
      font-weight: 600;
      font-size: 1.2rem;
      color: $blue-grey;
    }

    span {
      font-size: 0.8em;
      color: $white;
      top: 50%;
    }
  }
}


.testimonials-container {
  .column {
    background-size: cover;
    background-repeat: no-repeat;


  }


}

.carousel {
  overflow: hidden;
  min-height: 500px;
  .slider {
    min-height: 500px;
  }
  .slider-container {
    min-height: 500px;
  }
}

#testimonial-carousel .carousel-item {
    background-color: #F1F2F2;
    background-image: url(../images/general/maple-leaf-bg.svg);
    background-size: 100%;
    background-position-y: -200px;
    background-position-x: -200px;
    background-repeat: no-repeat;
    min-height: 600px;
    display: -ms-flexbox;
    display: flex;

    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
    background-size: 110%;
  min-height: 500px;
  p {
    color: #414042;
  }
  @media screen and (min-width: 768px) {
    padding-top: 10%;
  }

}