.modal-content {
  width: unset;
  overflow: initial;
  .heart-list li {
    font-weight: 300;
  }
  .container {
    max-height: 80vh;
    overflow-x: hidden;
    overflow-y: scroll;
    @media screen and (min-width: 768px){
      overflow: hidden;
    }
  }

  .column .is-8 {
    padding: 20px;
  }

  .column .is-4 {
    min-height: 300px;
  }

  .columns {
    display: flex;
    flex-direction: column-reverse;
    overflow: scroll;
  }

  @media screen and (min-width: 768px) {
    .column {
      min-height: 400px;
      padding: 80px;
    }
    .columns {
      flex-direction: row;
      overflow: auto;
    }
  }
}

.modal {
  z-index: 101;
  @media screen and (min-width: 768px) {
    justify-content: center;


  }
}

.modal-close {
  position: relative;
  float: right;
  top: 0;
  right: 0;

  &:before, &:after {
    background-color: black;
  }
}

.modal-column-text {
  padding: 40px;

  .heart-list {
    columns: 1;
    li {
      list-style: none;
    }
    &::marker {
      display: none;
    }
  }
;
  @media (min-width: 768px) {
    padding: 0px;
    .heart-list {
      padding: 0px 20px;
      columns: 2;
      li {
        margin-bottom:3px;
        list-style: none;
        &::marker {
          display: none;
        }
      }
    }
  ;

  }

}

.modal-column-image {
  background-position: center center !important;
  @media (max-width: 768px) {
    min-height: 300px;
  }

}