///* ==========================================================================
//Inputs styles
//========================================================================== */
//.field {
//
//  .label {
//    font-size: $size-7;
//    color: #D1D3D4;
//    font-weight: 300;
//  }
//
//  input.input {
//    color: #414042;
//    box-shadow: none !important;
//    transition: all 0.1s ease;
//    padding-bottom: 3px;
//    border: none;
//    font-size: 1.3125rem;
//    font-weight: 600;
//    border-bottom: 1px #E6E7E8 solid;
//    padding-left: 0;
//
//    &::placeholder {
//      color: #D1D3D4;
//
//    }
//
//
//    &.is-small {
//      padding-bottom: 2px;
//      padding-left: 10px;
//    }
//
//    &.is-medium {
//      padding-bottom: 5px;
//    }
//
//    &.is-large {
//      padding-bottom: 7px;
//    }
//
//    &:focus, &:active {
//      border-color: $primary;
//      border-bottom: 2px solid $primary;
//      border-radius: 0;
//
//      .label {
//        color: $primary;
//      }
//    }
//
//    &.rounded {
//      border-radius: 100px;
//    }
//
//    &.is-primary-focus:focus {
//      border-color: $primary;
//
//      ~ span.icon i {
//        color: $primary;
//      }
//    }
//
//    &.is-secondary-focus:focus {
//      border-color: $secondary;
//
//      ~ span.icon i {
//        color: $secondary;
//      }
//    }
//
//    &.is-accent-focus:focus {
//      border-color: $accent;
//
//      ~ span.icon i {
//        color: $accent;
//      }
//    }
//
//    &.is-bloody-focus:focus {
//      border-color: $bloody;
//
//      ~ span.icon i {
//        color: $bloody;
//      }
//    }
//  }
//}
//
//textarea.textarea {
//  color: #414042;
//  box-shadow: none !important;
//  transition: all 0.1s ease;
//  padding-bottom: 3px;
//  border: none;
//  font-size: 1.3125rem;
//  font-weight: 600;
//  border-bottom: 1px #E6E7E8 solid;
//  padding-left: 0;
//
//  &::placeholder {
//    color: #D1D3D4;
//
//  }
//  &:focus, &:active {
//    border: none;
//    box-shadow: none;
//    border-color: $primary;
//    border-bottom: 2px solid $primary;
//    border-radius: 0;
//
//    .label {
//      color: $primary;
//    }
//  }
//}
//
//.form-footer {
//  width: 100%;
//}

.group 			  {
  position:relative;
  margin-bottom:45px;
}
input, textarea 				{
  font-size:18px;
  padding:10px 10px 10px 5px;
  display:block;
  width:100%;
  border:none;
  border-bottom:1px solid #757575;
}
input:focus, textarea:focus 		{ outline:none; }

/* LABEL ======================================= */
label 				 {
  color:#999;
  font-size:18px;
  font-weight:normal;
  position:absolute;
  pointer-events:none;
  left:5px;
  top:10px;
  transition:0.2s ease all;
  -moz-transition:0.2s ease all;
  -webkit-transition:0.2s ease all;
}

/* active state */
input:focus ~ label, input:valid ~ label, textarea:focus ~ label, textarea:valid ~ label	{
  top:-20px;
  font-size:14px;
  color:#5264AE;
}

/* BOTTOM BARS ================================= */
.bar 	{ position:relative; display:block; width:100%; }
.bar:before, .bar:after 	{
  content:'';
  height:2px;
  width:0;
  bottom:1px;
  position:absolute;
  background:#5264AE;
  transition:0.2s ease all;
  -moz-transition:0.2s ease all;
  -webkit-transition:0.2s ease all;
}
.bar:before {
  left:50%;
}
.bar:after {
  right:50%;
}

/* active state */
input:focus ~ .bar:before, input:focus ~ .bar:after {
  width:50%;
}
textarea:focus ~ .bar:before, textarea:focus ~ .bar:after {
  width:50%;
}


/* HIGHLIGHTER ================================== */
.highlight {
  position:absolute;
  height:60%;
  width:100%;
  top:25%;
  left:0;
  pointer-events:none;
  opacity:0.5;
}

/* active state */
input:focus ~ .highlight, textarea:focus ~ .highlight {
  -webkit-animation:inputHighlighter 0.3s ease;
  -moz-animation:inputHighlighter 0.3s ease;
  animation:inputHighlighter 0.3s ease;
}

/* ANIMATIONS ================ */
@-webkit-keyframes inputHighlighter {
  from { background:#5264AE; }
  to 	{ width:0; background:transparent; }
}
@-moz-keyframes inputHighlighter {
  from { background:#5264AE; }
  to 	{ width:0; background:transparent; }
}
@keyframes inputHighlighter {
  from { background:#5264AE; }
  to 	{ width:0; background:transparent; }
}