@-webkit-keyframes spinAround {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0)
  }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg)
  }
}

@keyframes spinAround {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0)
  }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg)
  }
}

.slider {
  position: relative;
  width: 100%
}

.slider-container {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  min-height: 100%
}

.slider-container.is-vertical {
  flex-direction: column
}

.slider-container .slider-item {
  flex: none
}

.slider-container .slider-item .image.is-covered img {
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center center;
  object-position: center center;
  height: 100%;
  width: 100%
}

.slider-container .slider-item .video-container {
  height: 0;
  padding-bottom: 0;
  padding-top: 56.25%;
  margin: 0;
  position: relative
}

.slider-container .slider-item .video-container.is-1by1, .slider-container .slider-item .video-container.is-square {
  padding-top: 100%
}

.slider-container .slider-item .video-container.is-4by3 {
  padding-top: 75%
}

.slider-container .slider-item .video-container.is-21by9 {
  padding-top: 42.857143%
}

.slider-container .slider-item .video-container embed, .slider-container .slider-item .video-container iframe, .slider-container .slider-item .video-container object {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important
}

.slider-navigation-next, .slider-navigation-previous {
  display: flex;
  justify-content: center;
  align-items: center;
  fill: $primary;
  position: absolute;
  width: 42px;
  height: 42px;
  background: #fff center center no-repeat;
  background-size: 20px 20px;
  //border: 1px solid #fff;
  //box-shadow: 0 2px 5px #3232321a;
  top: 50%;
  margin-top: -20px;
  left: auto;
  right: 42px;
  top: 20px;
  cursor: pointer;
  transition: opacity .3s, -webkit-transform .3s;
  transition: transform .3s, opacity .3s;
  transition: transform .3s, opacity .3s, -webkit-transform .3s
}

.slider-navigation-next:hover, .slider-navigation-previous:hover {
  -webkit-transform: scale(1.2);
  transform: scale(1.2)
}

//.slider-navigation-next.is-hidden, .slider-navigation-previous.is-hidden {
//  display: none;
//  opacity: 0
//}

.slider-navigation-next svg, .slider-navigation-previous svg {
  width: 25%
}

.slider-navigation-next {
  left: auto;
  right: 0;
  background: #fff center center no-repeat;
  background-size: 20px 20px

  &::before {
    content: '/';
    color: $primary;

  }
}

.slider-pagination {
  display: none;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: .5rem 1rem;
  text-align: center
}

.slider-pagination .slider-page {
  background: #fff;
  width: 10px;
  height: 10px;
  border-radius: 25091983px;
  display: inline-block;
  margin: 0 3px;
  box-shadow: 0 2px 5px #3232321a;
  transition: -webkit-transform .3s;
  transition: transform .3s;
  transition: transform .3s, -webkit-transform .3s;
  cursor: pointer
}

.slider-pagination .slider-page.is-active, .slider-pagination .slider-page:hover {
  -webkit-transform: scale(1.4);
  transform: scale(1.4)
}

@media screen and (min-width: 800px) {
  .slider-pagination {
    display: flex
  }
}

.hero.has-carousel {
  position: relative
}

.hero.has-carousel + .hero-body, .hero.has-carousel + .hero-footer, .hero.has-carousel + .hero-head {
  z-index: 10;
  overflow: hidden
}

.hero.has-carousel .hero-carousel {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: auto;
  border: none;
  margin: auto;
  padding: 0;
  z-index: 0
}

.hero.has-carousel .hero-carousel .slider {
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  height: 100% !important;
  max-height: 100%;
  z-index: 0
}

.hero.has-carousel .hero-carousel .slider .has-background {
  max-height: 100%
}

.hero.has-carousel .hero-carousel .slider .has-background .is-background {
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center center;
  object-position: center center;
  height: 100%;
  width: 100%
}

.hero.has-carousel .hero-body {
  margin: 0 3rem;
  z-index: 10
}