/* ==========================================================================
Main SCSS file / Fresh
========================================================================== */

//Imports
@import 'partials/initial';
@import 'partials/typography';
@import 'partials/colors';
@import 'partials/navbar';
@import 'partials/dropdowns';
@import 'partials/body';
@import 'partials/sections';
@import 'partials/hero';
@import 'partials/footer';
@import 'partials/buttons';
@import 'partials/cards';
@import 'partials/forms';
@import 'partials/animations';
@import 'partials/sidebar';
@import 'partials/testimonials';
@import 'partials/responsive';
@import 'partials/utils';
@import 'partials/falight';
@import 'partials/servicemap';
@import 'partials/quote';
@import 'partials/modal';
@import 'partials/tabs';
@import 'partials/locations';
@import 'partials/iconcard';
@import 'partials/services';