/* ==========================================================================
Classes to change the feel of bulma buttons
========================================================================== */

// CTA buttons 


.button {
  border-radius: 2px;
}
.button {
  cursor: pointer;
  transition: all 0.5s;

  &.cta {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1rem;
    font-weight: 600;
    padding: 26px 40px 26px 40px;
    background-color: #F1F2F2;
    border: none;
    text-transform: uppercase;
    color: #414042;
    transition: all .3s ease;
    &:hover {
      background: $primary;
      color: white;
    }
  }

  &.is-clear {
    line-height: 0 !important;
  }

  &.rounded {
    border-radius: 500px;
  }

  &.raised:hover {
    box-shadow: 0 14px 26px -12px rgba(0, 0, 0, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2) !important;
    opacity: 0.8;
  }

  &.btn-outlined {
    background: transparent;
  }

  &.signup-button {
    font-size: $size-7;
    font-weight: 600;
    font-family: 'Open Sans', sans-serif;
    padding: 24px 26px;
    width: 130px;
  }
}

.button {
  &.primary-btn {
    outline: none;
    border-color: $white;
    background-color: transparent;
    color: $white;
    transition: all 0.3s ease;

    &.is-largesmalltext {
      font-size: 1rem;
      padding: 40px 160px;
      text-transform: uppercase;
      @media screen and (max-width: 768px) {
        padding: 30px 50px;
      }
    }

    &:hover {
      color: black;
      background-color: white;
      box-shadow: $light-grey 5px;
      transition: all .3s ease;
    }

    &.raised:hover {
      box-shadow: 0 14px 26px -12px rgba(79, 193, 234, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(79, 193, 234, 0.2) !important;
      opacity: 0.8;
      box-shadow: 10px 10px 5px #aaaaaa;

    }

    &.btn-outlined {
      border-color: $primary;
      color: $primary;
      background-color: transparent;

      &:hover {
        color: $white;
        background-color: $primary;
      }
    }
  }

  &.secondary-btn {
    outline: none;
    border-color: $secondary;
    background-color: $secondary;
    border-width: 3px;
    color: $white;
    transition: all 0.5s;
    font-weight: bold;

    &.is-largesmalltext {
      font-size: 1rem;
      padding: 40px 160px;
      text-transform: uppercase;
      @media screen and (max-width: 768px) {
        padding: 30px 50px;
      }
    }

    &:hover {
      color: $white;
    }

    &.raised:hover {
      box-shadow: 0 14px 26px -12px rgba(243, 146, 0, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(243, 146, 0, 0.2) !important;
      opacity: 0.8;
    }

    &.btn-outlined {
      border-color: $primary;
      color: $primary;
      border-width: 3px;
      background-color: transparent;

      &:hover {
        color: $white;
        background-color: $secondary;
      }
    }
  }

  &.button.accent-btn {
    outline: none;
    border-color: $accent;
    background-color: $accent;
    color: $white;
    transition: all 0.5s;

    &.is-largesmalltext {
      font-size: 1rem;
      padding: 40px 160px;
      text-transform: uppercase;
      @media screen and (max-width: 768px) {
        padding: 30px 50px;
      }
    }

    &:hover {
      color: $white;
    }

    &.raised:hover {
      box-shadow: 0 14px 26px -12px rgba(104, 187, 136, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(104, 187, 136, 0.2) !important;
      opacity: 0.8;
    }

    &.btn-outlined {
      border-color: $accent;
      color: $accent;
      background-color: transparent;

      &:hover {
        color: $white;
        background-color: $accent;
      }
    }
  }

  &.heart-btn {
    outline: none;
    border-color: $white;
    border-width: 3px;
    background-color: transparent;
    background-image: url('../images/general/leaf-bg-white.svg');
    background-size: 100%;
    background-position-y: -75px;
    background-position-x: -30px;
    background-repeat: no-repeat;
    color: $white;
    transition: all 0.3s ease;
    font-size: $size-7;
    padding: 40px 160px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    font-weight: 600;
    button {
      padding: 40px 160px 50px 160px !important;
      @media screen and (max-width: 768px) {
        padding: 30px 50px 40px 50px !important;

      }
    }

    @media screen and (max-width: 768px) {
      padding: 30px 50px;
      width: 100%;

    }

    &:hover {
      color: $white;
      background-color: $primary;
      transition: all .3s ease;
    }

    &.raised:hover {
      box-shadow: 0 14px 26px -12px rgba(104, 187, 136, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(104, 187, 136, 0.2) !important;
      opacity: 0.8;
    }

    &.btn-outlined {
      border-color: $primary;
      color: $primary;
      background-color: transparent;
      background-image: url('../images/general/leaf-bg-blue.svg');


      &:hover {
        color: $white;
        background-color: $primary;
        background-image: url('../images/general/leaf-bg-white.svg');

      }
    }
  }
}

button.heart-btn {
  outline: none;
  border-color: $white;
  border-width: 3px;
  background-color: transparent;
  background-image: url('../images/general/leaf-bg-white.svg');
  background-size: 100%;
  background-position-y: -75px;
  background-position-x: -30px;
  background-repeat: no-repeat;
  color: $white;
  transition: all 0.3s ease;
  font-size: $size-7;
  padding: 40px 160px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-weight: 600;
  button {
    padding: 40px 160px 50px 160px !important;
    @media screen and (max-width: 768px) {
      padding: 30px 50px 40px 50px !important;

    }
  }

  @media screen and (max-width: 768px) {
    padding: 30px 50px;
    width: 100%;

  }

  &:hover {
    color: $white;
    background-color: $primary;
    transition: all .3s ease;
  }

  &.raised:hover {
    box-shadow: 0 14px 26px -12px rgba(104, 187, 136, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(104, 187, 136, 0.2) !important;
    opacity: 0.8;
  }

  &.btn-outlined {
    border-color: $primary;
    border: solid;
    color: $primary;
    background-color: transparent;
    background-image: url('../images/general/leaf-bg-blue.svg');


    &:hover {
      color: $white;
      background-color: $primary;
      background-image: url('../images/general/leaf-bg-white.svg');

    }
  }
}
