
.services-container {
  .column {
    position: relative;
    min-height: 250px;
    background-size: cover ;
    background-repeat: no-repeat !important;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-position: center center !important;
    transition: all .3s ease-in-out;

    .overlay {
      position: absolute;
      z-index: 1;
      opacity: 0;
      width: 100%;
      height: 100%;
      background: $primary;
      outline: 3px solid transparent;
      transition: all .3s ease;
    }

    &:hover {
      .overlay {
        opacity: .7;
        outline: 3px solid white;
        outline-offset: -30px;
      }
    }

    &:before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: inherit;
      content: ' ';
      z-index: -2;
    }

    &.service1 {
      background: url('../images/services/services-1.jpg');


    }

    &.service2 {
      background: url('../images/services/services-2.jpg');
    }

    &.service3 {
      background: url('../images/services/services-3.jpg');
    }

    &.service4 {
      background: url('../images/services/services-4.jpg');
    }

    &.service5 {
      background: url('../images/services/services-5.jpg');
    }

    &.service6 {
      background: url('../images/services/services-6.jpg');
    }


    &:hover {

    }


    @media (min-width: 768px) {
      min-height: 400px;

    }

    .modal-trigger {
      margin-top: -20px;
      z-index: 2;
    }
  }

  .services-title {
    color: white;
    font-size: $size-4;
    font-weight: 600;
    text-transform: uppercase;
    z-index: 2;
    @media screen and (min-width:768px){
      padding: 20px;
    }
  }

}

.bg-container-fixed {
  min-height: 50%;
  max-height: 100vh;
  background-position: center center;
  background-size: cover !important;
  background-repeat: no-repeat !important;

  &-fullheight {
    min-height: 200px;
    height: 200px;
    background-position: center center !important;
    background-size: cover !important;
    background-repeat: no-repeat;
    @media screen and (min-width: 768px) {
      height: 100%;
      min-height: 100%;

    }
  }
}
