.serviceMap {
  width: 100%;
  height: auto;

  [id^='label-'] {
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s;
  }

  [id^='map-'] {
    transition: all 0.3s;

    &:hover {
      fill: #366DB5 !important;

      * {
        fill: #366DB5 !important;
      }
    }
  }
}